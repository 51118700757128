<template>
  <div class="register-main register-main--safe-padding pa-0" style="position: relative">
    <div class="fill-width">
      <ModalHeader
        style="margin-bottom: 24px"
        :content-sections="['close', 'title', 'subtitle']"
        :title-text="'profile_example.title'"
        :subtitle-text="'profile_example.subtitle'"
        close-no-margin
        center-subtitle
        secondary-color-title
        secondary-color-subtitle
        @close="closeMethod()"
      />
      <ModalContent
        style="border-radius: 16px;"
        :content-sections="['imageAtom']"
        :image-source="$t('profile_example.gif')"
        image-rounded-wide
      />
    </div>
    <CallToActions
      :types="['svgLogo']"
      :svg-logo="'explorer-main.svg'"
      :spaced="false"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileExample',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
  },
  computed: {
    ...mapGetters({
      partnershipLabels: 'options/partnershipLabels',
      campus: 'elasticSearch/campus',
    }),
  },
  methods: {
    closeMethod() {
      this.$emit('closeModal');
    },
  },
};
</script>
