<template>
  <div class="register-main register-main--safe-padding pa-0" style="position: relative">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        @back="$emit('backStep')"
      />
      <ModalContent
        :content-sections="['subtitle', 'primaryText']"
        :subtitle-text="'preexist_login.confirm_info.title'"
        primary-text-secondary-color
        bold-primary-text
        :primary-text-font-family="'poppins'"
        :primary-text-label="'preexist_login.confirm_info.user_subtitle'"
      />
      <ModalContent
        style="margin-top: 28px"
        :content-sections="['infoBox']"
        :info-box-text="getLgName"
        info-padding
        info-box-fill-width
        info-box-align-left
        :info-box-icon="'person-primary-300.svg'"
        :info-box-tag="'click_preexist_register-lg-info'"
        :info-box-content-array="getUserInfo"
      />
      <section v-if="students.length > 0">
        <ModalContent
          :content-sections="['primaryText']"
          :subtitle-text="'preexist_login.confirm_info.title'"
          primary-text-secondary-color
          bold-primary-text
          :primary-text-font-family="'poppins'"
          :primary-text-label="'preexist_login.confirm_info.applicant_subtitle'"
        />
        <section v-for="applicantInfo, index in getApplicantsInfo" :key="index">
          <ModalContent
            :content-sections="['infoBox']"
            :info-box-text="applicantInfo[0].name"
            info-padding
            info-box-fill-width
            info-box-align-left
            :info-box-icon="'face-primary-300.svg'"
            :info-box-tag="'click_preexist_register-lg-info'"
            :info-box-content-array="applicantInfo"
          />
        </section>
      </section>
      <ModalContent
        v-if="CONFIG.tenant === 'newhaven'"
        style="width: 100%;"
        :content-sections="['primaryText']"
        :primary-text-label="'preexist_login.confirm_info.footer.reminder'"
      />
      <ModalContent
        style="width: 100%; margin-top: 32px;"
        :content-sections="['MultiStyleText']"
        :multi-text-array="getContactInfoArray"
      />
    </div>
    <CallToActions
      :link-text="'views.login.to-register'"
      :types="['main']"
      :main-button-text="'headmaster_register.welcome.btn'"
      :main-button-tag="'click_partners_accept'"
      :main-button-payload="'click_partners_accept'"
      active-value
      :spaced="false"
      :loader="confirmLoader"
      @continue="gotoMap()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import CONFIG from '@/config';
import { getLocaleProperty } from '@/utils/locale';

export default {
  name: 'PreexistConfirmInfo',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
  },
  data() {
    return {
      CONFIG,
      confirmLoader: false,
    };
  },
  computed: {
    ...mapGetters({
      userEmail: 'authentication/userEmail',
      legalGuardian: 'authentication/legalGuardian',
      students: 'authentication/students',
      gradesOptions: 'options/grades',
    }),
    getUserInfo() {
      const userInfoArray = [
        {
          title: 'preexist_login.confirm_info.arrayInfo.title_email',
          content: this.userEmail,
        },
        {
          title: 'preexist_login.confirm_info.arrayInfo.title_location',
          content: this.getLgAddress,
        },
        {
          title: 'preexist_login.confirm_info.arrayInfo.title_zip_code',
          content: this.getLgZipCode,
        },
      ];
      return userInfoArray;
    },
    getApplicantsInfo() {
      const applicantInfoArray = [];
      this.students.forEach((student) => {
        applicantInfoArray.push([
          {
            name: this.getStudentName(student),
            title: 'preexist_login.confirm_info.arrayInfo.title_gender',
            content: student.gender?.gender_label,
          },
          {
            title: 'preexist_login.confirm_info.arrayInfo.title_birth_date',
            content: student.birth_date,
          },
          {
            title: 'preexist_login.confirm_info.arrayInfo.title_grade',
            content: student.grades.length > 0 ? this.getGradeName(student.grades[0]) : null,
          },
        ]);
      });
      return applicantInfoArray;
    },
    getLgAddress() {
      if (this.legalGuardian && this.legalGuardian.address && this.legalGuardian.address.length > 0) {
        return `${this.legalGuardian.address[0].address_details}`;
      }
      return '';
    },
    getLgName() {
      if (this.legalGuardian && (this.legalGuardian.firstName || this.legalGuardian.firstLastname)) {
        return `${this.legalGuardian.firstName ? this.legalGuardian.firstName : ''} ${this.legalGuardian.firstLastname ? this.legalGuardian.firstLastname : ''}`;
      }
      return '';
    },
    getLgZipCode() {
      if (this.legalGuardian && this.legalGuardian.zipCode) {
        return this.legalGuardian.zipCode;
      }
      return '';
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getContactInfoArray() {
      const textSection = [];
      // ARRAY objectKey for text set styles with index
      if (this.$i18n.locale !== 'en') {
        textSection.push(
          {
            text: this.isMobile ? 'preexist_login.confirm_info.footer.contact.allText' : 'preexist_login.confirm_info.footer.contact.text',
            text2: this.isMobile ? '' : 'preexist_login.confirm_info.footer.contact.text2',
            text3: this.getContactInfo,
            tag: 'click_preexist_confirm_info_contact',
            bold: [1, 2, 3],
            underline: [3],
            small: [1, 2, 3],
          },
        );
      } else {
        textSection.push(
          {
            text: 'preexist_login.confirm_info.footer.contact.textAll',
            text2: this.getContactInfo,
            tag: 'click_preexist_confirm_info_contact',
            bold: [1, 2],
            underline: [2],
            small: [1, 2],
          },
        );
      }
      return textSection;
    },
    getContactInfo() {
      if (CONFIG.tenant === 'colombia' || CONFIG.tenant === 'palmira') {
        return 'colombia@explorador.com';
      }
      if (CONFIG.tenant === 'chile') {
        return 'chile@explorador.com';
      }
      return 'contact@explore.newhavenmagnetschools.com';
    },
  },
  methods: {
    getStudentName(student) {
      return `${student.first_name ? student.first_name : ''} ${student.first_lastname ? student.first_lastname : ''}`;
    },
    gotoMap() {
      this.confirmLoader = true;
      this.$emit('nextStep');
    },
    getGradeName(gradeId) {
      const selectedGrade = this.gradesOptions.find((grade) => grade.id === gradeId);
      return getLocaleProperty(selectedGrade, 'grade_name');
    },
  },
};
</script>
