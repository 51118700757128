<template>
  <div class="register-main register-main--spaced">
    <div
      class="fill-width d-flex flex-column justify-center align-center"
      style="margin-bottom: 40px"
    >
      <ModalHeader
        :content-sections="['actions', 'logo']"
        :title-text="'views.login.password-recovery.title'"
        inline-logo
        @back="backMethod()"
      />
      <ModalContent
        style="margin-top: 40px;"
        :content-sections="['subtitle', 'registerInput', 'passValidation']"
        :subtitle-text="'headmaster_register.reset_password.title'"
        :input-section="inputContent"
        @setValue="setValue"
      />
    </div>
    <CallToActions
      :types="['main']"
      :active-value="isActive"
      :loader="resetLoading"
      :main-button-text="'headmaster_register.reset_password.btn'"
      :main-button-tag="'click_partners_continue2'"
      :spaced="false"
      @continue="changePassword"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import { isValidEmail } from '@/utils/strings';
import { mapActions, mapGetters } from 'vuex';
import userRegistrationServices from '@/services/userRegistration.services';

export default {
  name: 'ResetPasswordModal',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
  },
  props: {
    roleId: {
      type: Number,
      default: 3,
    },
    campusCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      inputContent: [
        {
          value: '',
          noInfoError: false,
          placeholder: 'views.login.name.password',
          inputType: 'password',
          password: 0,
          show: false,
        },
        {
          value: '',
          noInfoError: false,
          placeholder: 'views.login.name.password',
          inputType: 'password',
          password: 1,
          show: true,
        },
      ],
      password: '',
      noInfoError: false,
      resetLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      resetPasswordStatus: 'authentication/resetPasswordStatus',
      currentHeadMasterMainSchool: 'roles/currentHeadMasterMainSchool',
      userEmail: 'authentication/userEmail',
      userPhone: 'authentication/userPhone',
      usernameCheck: 'authentication/usernameCheck',
    }),
    isActive() {
      if (this.password) {
        return true;
      }
      return false;
    },
  },
  watch: {
    currentHeadMasterMainSchool: {
      handler(newVal) {
        if (newVal) {
          this.resetLoading = false;
          this.$router.push('/dashboard');
        }
      },
    },
  },
  methods: {
    ...mapActions({
      success: 'utils/success',
      error: 'utils/error',
      tokenResetPassword: 'authentication/tokenResetPassword',
      doLogin: 'authentication/doLogin',
      usernameCheck: 'authentication/usernameCheck',
    }),
    changePassword() {
      if (!this.password) {
        this.noInfoError = true;
        return this.noInfoError;
      }
      this.resetLoading = true;
      this.tokenResetPassword({
        password1: this.password,
        password2: this.password,
        callback: () => {
          this.raiseSnackbar();
        },
        legalGuardian: true,
      });
      return null;
    },
    setValue(mainValue, passwordInput, index) {
      if (passwordInput === 1) {
        this.password = mainValue;
      }
      this.inputContent[index].value = mainValue;
      this.inputContent[index].noInfoError = false;
    },
    raiseSnackbar() {
      if (this.resetPasswordStatus === 'OK') {
        this.doLogin({
          username: this.usernameCheck,
          email: isValidEmail(this.userEmail) ? this.userEmail : '',
          password: this.password,
          phone: this.userPhone,
        })
          .then(() => {
            userRegistrationServices.submitTermsAndCondition(
              this.roleId === 2 ? 'tac_preexist' : 'tac_headmaster',
              { accepted: true, campusCode: this.campusCode },
            ).then(() => {
              this.success(this.$t('views.reset_password.password_changed'));

              if (this.roleId === 2) {
                return this.$emit('nextStep');
              }
              return this.$router.push('/map');
            });
          });
      } else {
        this.error(this.$t('views.reset_password.password_not_changed'));
      }
    },
    backMethod() {
      this.$emit('backStep');
    },
  },
};
</script>
