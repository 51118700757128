<template>
  <div class="register-main pa-0 h-full" style="position: relative">
    <ModalHeader
      :content-sections="['actions', 'logo']"
      inline-logo
      @back="backMethod()"
    />
    <UserConsent
      :consent-codes="consents"
      :partner="partner"
      class="h-full"
      @submitted="$emit('nextStep')"
    >
      <template #actions="{ submit, missingConsents }">
        <div class="bottom-0 relative">
          <ModalContent
            style="padding: 4px 24px;"
            :content-sections="['primaryText']"
            :primary-text-label="'headmaster_register.privacy_policy.terms'"
            disabled-primary-text
          />
          <CallToActions
            :link-text="'views.login.to-register'"
            :types="['main']"
            :main-button-text="'headmaster_register.privacy_policy.btn'"
            :main-button-tag="'click_partners_accept'"
            :main-button-payload="'click_partners_accept'"
            :active-value="!missingConsents"
            :spaced="false"
            @continue="submit()"
          />
        </div>
      </template>
    </UserConsent>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import UserConsent from '@/components/organisms/general/UserConsent.vue';

export default {
  name: 'PreexistPrivacyPolicy',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    UserConsent,
  },
  props: {
    partnerId: {
      type: String,
      default: null,
    },
    roleId: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    ...mapGetters({
      partnershipLabels: 'options/partnershipLabels',
    }),
    partner() {
      const parsedId = parseInt(this.partnerId, 10);
      if (parsedId > 0) {
        return this.partnershipLabels.find((partnership) => partnership.id === parsedId);
      }
      return null;
    },
    consents() {
      const consentCodes = [];
      if (this.partner) {
        consentCodes.push('partnership');
      }
      if (this.roleId === 3) {
        consentCodes.push('tac_headmaster');
      }
      consentCodes.push('tether_base');
      return consentCodes;
    },
  },
  methods: {
    backMethod() {
      this.$emit('backStep');
    },
  },
};
</script>
