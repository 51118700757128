<template>
  <div class="fill-width d-flex flex-column justify-space-between align-center fill-height">
    <div class="mt-10" style="width: 300px">
      <ModalHeader
        :content-sections="['title', 'logo', 'partnershipLogo']"
        :title-text="'headmaster_register.welcome.title'"
        :partner-logo="partnerImage"
        medium-logo
        @back="backMethod()"
      />
      <ModalContent
        style="margin-top: 74px"
        :content-sections="roleId === 2 ? ['MultiStyleText'] : ['primaryText']"
        :multi-text-array="multiStyleText"
        :primary-text-label="setContentText()"
        center-primary-text
        @linkClick="showCampusDetailGif()"
      />
    </div>
    <CallToActions
      :style="'margin-top: 48px'"
      :link-text="'views.login.to-register'"
      :types="['main']"
      :main-button-text="'headmaster_register.welcome.btn'"
      :main-button-tag="'click_partners_continue1'"
      active-value
      :spaced="false"
      @continue="$emit('nextStep')"
    />
    <v-dialog
      v-model="showInfoModals"
      transition="slide-x-transition"
      :content-class="isMobile ? 'inner-mobile-modal-dialog' : 'inner-modal-dialog'"
    >
      <ProfileExample
        @closeModal="showInfoModals = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import ProfileExample from '@/components/organisms/general/ProfileExample.vue';
import CONFIG from '@/config';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HeadmasterWelcome',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    ProfileExample,
  },
  props: {
    partnerId: {
      type: String,
      default: null,
    },
    roleId: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      showInfoModals: false,
    };
  },
  computed: {
    ...mapGetters({
      partnershipLabels: 'options/partnershipLabels',
    }),
    partnerImage() {
      const mainPartner = this.partnershipLabels.find((partner) => partner.id === parseInt(this.partnerId, 10));
      if (mainPartner?.image) {
        return mainPartner.image;
      }
      return null;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    multiStyleText() {
      let argsValue;

      if (CONFIG.tenant === 'newhaven') {
        argsValue = 'New Haven';
      } else if (CONFIG.tenant === 'dom') {
        argsValue = 'RD';
      } else {
        argsValue = CONFIG.tenant;
      }

      const textSection = [
        {
          text: 'preexist_login.welcome_content.text1',
          args: { args: CONFIG.tenant === 'newhaven' ? 'New Haven' : CONFIG.tenant },
          text2: 'preexist_login.welcome_content.text2',
          args2: { args: argsValue },
          text3: 'preexist_login.welcome_content.text3',
          text4: 'preexist_login.welcome_content.text4',
          tag: 'click_preexist_login_reg_welcome-content',
          link: this.$i18n.locale === 'en' ? [2] : [3],
          spaced: [3],
          center: [1, 2, 3, 4],
        },
      ];
      return textSection;
    },
  },
  methods: {
    ...mapActions({
    }),
    backMethod() {
      this.$emit('backStep');
    },
    setContentText() {
      if (this.partnerId === '1' && CONFIG.tenant === 'chile') {
        return 'headmaster_register.welcome.content2';
      }
      return 'headmaster_register.welcome.content';
    },
    showCampusDetailGif() {
      this.showInfoModals = true;
    },
  },
};
</script>
