<template>
  <section class="login">
    <v-dialog
      v-model="showHeadmasterRegister"
      persistent
      :max-width="'480'"
      transition="slide-x-transition"
      :content-class="isMobile ? 'headmaster-dialog-mobile' : 'headmaster-dialog'"
    >
      <PreexistLoginFlow
        :partner-id="partnerId"
        :campus-code="campusCode"
      />
    </v-dialog>
    <BaseSnackbar />
  </section>
</template>

<script>
import BaseSnackbar from '@/components/explorer/base/BaseSnackbar.vue';
import PreexistLoginFlow from '@/components/templates/PreexistLoginFlow.vue';
import { mapActions } from 'vuex';

export default {
  name: 'HeadmasterRegister',
  components: {
    PreexistLoginFlow,
    BaseSnackbar,
  },
  metaInfo() {
    return {
      title: 'Registro Directores',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            'Crea una cuenta en nuestro explorador y accede a '
            + 'información personalizada de colegios y procesos de admisión.',
        },
      ],
    };
  },
  data() {
    return {
      showHeadmasterRegister: false,
      partnerId: null,
      campusCode: null,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    this.showHeadmasterRegister = true;
    const routeParams = this.$route.params;
    this.getUserMainInfo({ uuid: routeParams.userUuid });
    this.partnerId = routeParams.partnerId;
    this.campusCode = routeParams.code;
  },
  methods: {
    ...mapActions({
      getUserMainInfo: 'authentication/getUserMainInfo',
    }),
  },
};
</script>
